import AnimatedT from "./AnimatedT";

const NavBar = () =>{
    return (
        <div className="NavBar">
            <br/>
            <a href="./">
                <AnimatedT/>
            </a>
        </div>
    );
}

export default NavBar;