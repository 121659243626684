const WelcomeBar = () =>{
    return (
        <div className="App">
            <button
                className="WelcomeButton"
                onClick={(e) => {
                    e.preventDefault();
                    window.location.href='https://www.youtube.com/watch?v=dQw4w9WgXcQ';
                }}>
                WELCOME
            </button>
            <br/>
        </div>
    );
}

export default WelcomeBar;