import React, { Component } from 'react';

import './App.css';
import './css/WelcomeButton.css';
import './css/NavBar.css';
import './css/ButtonBar.css';
import './css/Logo.css';
import './css/Heart.css';
import './css/Footer.css';

import LandingPage from "./pages/LandingPage";
import ErrorPage from "./pages/Error";
import MenuPage from "./pages/MenuPage";

import {
    HashRouter as Router,
    Route,
    Routes,
} from "react-router-dom";

class App extends Component {
  render() {
    return (
        <div>
            <LandingPage/>
        </div>
    );
  }
}

export default App;
