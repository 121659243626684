import React, { Component } from 'react';

import "../css/AnimatedLogo.css"

import logo0 from '../images/logo (num)/logo (0).svg';
import logo1 from '../images/logo (num)/logo (1).svg';
import logo2 from '../images/logo (num)/logo (2).svg';
import logo3 from '../images/logo (num)/logo (3).svg';
import logo4 from '../images/logo (num)/logo (4).svg';
import logo5 from '../images/logo (num)/logo (5).svg';
import logo6 from '../images/logo (num)/logo (6).svg';
import logo7 from '../images/logo (num)/logo (7).svg';
import logo8 from '../images/logo (num)/logo (8).svg';
import logo9 from '../images/logo (num)/logo (9).svg';
import logo10 from '../images/logo (num)/logo (10).svg';
import logo11 from '../images/logo (num)/logo (11).svg';
import logo12 from '../images/logo (num)/logo (12).svg';
import logo13 from '../images/logo (num)/logo (13).svg';
import logo14 from '../images/logo (num)/logo (14).svg';
import logo15 from '../images/logo (num)/logo (15).svg';
import logo16 from '../images/logo (num)/logo (16).svg';
import logo17 from '../images/logo (num)/logo (17).svg';
import logo18 from '../images/logo (num)/logo (18).svg';
import logo19 from '../images/logo (num)/logo (19).svg';
import logo20 from '../images/logo (num)/logo (20).svg';
import logo21 from '../images/logo (num)/logo (21).svg';

class AnimatedLogo extends Component {
    constructor(props) {
        super(props);
        this.switchImage = this.switchImage.bind(this);
        this.state = {
            currentImage: 0,
            images: [
                logo0,
                logo1,
                logo2,
                logo3,
                logo4,
                logo5,
                logo6,
                logo7,
                logo8,
                logo9,
                logo10,
                logo11,
                logo12,
                logo13,
                logo14,
                logo15,
                logo16,
                logo17,
                logo18,
                logo19,
                logo20,
                logo21
            ]
        };
    }

    switchImage() {
        if (this.state.currentImage < this.state.images.length - 1) {
            this.setState({
                currentImage: this.state.currentImage + 1
            });
        }
        return this.currentImage;
    }

    componentDidMount() {
        setInterval(this.switchImage, 100);
    }

    render() {
        return (
            <div className="App">
                <img className="AnimatedLogo"
                    src={this.state.images[this.state.currentImage]}
                    alt="cleaning images"
                />
            </div>
        );
    }

}

export default AnimatedLogo;