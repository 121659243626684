import React, { Component } from 'react';
import App from "../App";
import MenuBars from "../components/MenuBars";

class MenuPage extends Component {
    render() {
        return (
            <div>
                <MenuBars>
                </MenuBars>
            </div>
        );
    }
}

export default MenuPage;