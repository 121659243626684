import NavBar from "../components/NavBar";
import AnimatedLogo from "../components/AnimatedLogo";
import WelcomeButton from "../components/WelcomeButton";
import ButtonBar from "../components/ButtonBar";
import Footer from "../components/Footer";

const LandingPage = () =>{
    return (
        <div>
            { /* nav bar */ }
            <NavBar/>

            { /* cover logo */ }
            <AnimatedLogo/>

            { /* welcome button */ }
            <WelcomeButton/>

            { /* button bar */ }
            <ButtonBar/>

            { /* footer */ }
            <Footer/>
        </div>
    );
}

export default LandingPage;