import React, { Component } from 'react';

import logo1 from '../images/T/T0.svg';
import logo2 from '../images/T/T1.svg';
import logo0 from '../images/T/T2.svg';
import logo3 from '../images/T/T3.svg';
import logo4 from '../images/T/T4.svg';

import "../css/AnimatedT.css"

class AnimatedT extends Component {
    constructor(props) {
        super(props);
        this.switchImage = this.switchImage.bind(this);
        this.state = {
            currentImage: 0,
            images: [
                logo0,
                logo1,
                logo2,
                logo3,
                logo4,
            ]
        };
    }

    switchImage() {
        if (this.state.currentImage < this.state.images.length - 1) {
            this.setState({
                currentImage: this.state.currentImage + 1
            });
        } else {
            this.setState({
                currentImage: 0
            });
        }
        return this.currentImage;
    }

    componentDidMount() {
        setInterval(this.switchImage, 500);
    }

    render() {
        return (
            <div>
                <img className="Animated T"
                    src={this.state.images[this.state.currentImage]}
                    alt="cleaning images"
                />
            </div>
        );
    }

}

export default AnimatedT;