import React, { Component } from 'react';

import mail  from "../images/button logos/mail.svg";
import linkedin  from "../images/button logos/linkedin.svg";
import cv from "../images/button logos/cv.svg";
import github from "../images/button logos/github.svg";

import '../css/ButtonLinks.css';
import '../css/ButtonLinksImg.css';

class ButtonLinks extends Component {
  goToMail() {
    window.location.href = "mailto:thienkt@cs.washington.edu"
  }
  
  goToLinkedin() {
    window.open("https://www.linkedin.com/in/thien-tran-068234223")
  }

  goToCV() {
    window.location.href = "mailto:thienkt@cs.washington.edu?subject=CV Request&body=Hi, can I request a copy of your CV?"
  }

  goToGitHub() {
    window.open("https://github.com/thienktran")
  }

  renderType() {
    if (this.props.type === "mail") {
      return (
        <img src={mail}
             className="ButtonLinksImg"
             alt={"Mail Icon"}
             onClick={ this.goToMail.bind(this) }/>
      );
    } else if (this.props.type === "linkedin") {
      return (
        <img src={linkedin}
             className="ButtonLinksImg"
             alt={"LinkedIn Icon"}
             onClick={ this.goToLinkedin.bind(this) }/>
      );
    } else if (this.props.type === "cv") {
      return (
        <img src={cv}
             className="ButtonLinksImg"
             alt={"CV Icon"}
             onClick={ this.goToCV.bind(this) }/>
      );
    } else {
      return (
        <img src={github}
             className="ButtonLinksImg"
             alt={"GitHub Icon"}
             onClick={ this.goToGitHub.bind(this) }/>
      );
    }
  }

  render() {
    return (
      <div className='ButtonLinks'>
        { this.renderType() }
      </div>
    );
  }
}

export default ButtonLinks;
  