import ButtonLinks from './ButtonLinks.js'

const ButtonBar = () =>{
    return (
        <div className='ButtonBar'>
            <ButtonLinks type="linkedin"/>
            <ButtonLinks type="cv" />
            <ButtonLinks type="mail" />
            <ButtonLinks type="github"/>
        </div>
    );
}

export default ButtonBar;