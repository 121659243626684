import React, { Component } from 'react';
import MenuBar from "./MenuBar";

import icon from "../images/abstract.svg"

class MenuBars extends Component {
    render() {
        return (
            <div>
                <MenuBar type={"about me"} icon={icon}/>
                <MenuBar type={"classes"} icon={""}/>
                <MenuBar type={"books"} icon={""}/>
            </div>
        );
    }
}

export default MenuBars;