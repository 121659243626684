import React, { Component } from 'react';

class MenuBar extends Component {
    render() {
        return (
            <div>
                <div>
                    <img src={this.props.icon} width="20%"/>
                    {this.props.type}
                </div>
            </div>
        );
    }
}

export default MenuBar;